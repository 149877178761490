* {
  font-family: serif;
}

.logo-dashboard {
  width: 200px;
  height: 55px;
}

.logo2-home {
  width: 270px;
  height: 60px;
}

.logo-detail {
  width: 250px;
  height: 100px;
  position: fixed;
  right: 115px;
}

.foto-dashboard {
  margin-top: 10px;
  width: 140px;
  height: 150px;
  border-radius: 10px;
  background-color: rgb(211, 207, 207);
  transition: transform 0.3s cubic-bezier(0.445, 0.05, 0.55, 0.95);
}

.foto-dashboard:hover {
  transform: scale(1.05);
}

.foto-employee {
  width: 200px;
  height: 200px;
  border-radius: 100px;
  background-color: transparent;
  position: absolute;
  top: 20px;
  right: 30px;
  border: 1px solid grey;
  transition: transform 0.3s cubic-bezier(0.445, 0.05, 0.55, 0.95);
}

.image-license {
  width: 800px;
  height: 400px;
  border-radius: 20px;
  background-color: transparent;
  position: absolute;
  top: 20px;
  right: 30px;
  border: 1px solid grey;
  transition: transform 0.3s cubic-bezier(0.445, 0.05, 0.55, 0.95);
}

.foto-employee:hover {
  transform: scale(1.05);
}

.foto-employee-update {
  width: 150px;
  height: 150px;
  border-radius: 10px;
  border: 1px solid black;
}

span {
  font-weight: bold;
}

.bi-arrow-left {
  font-size: 30px;
  position: fixed;
  left: 50px;
  top: 80px;
  cursor: pointer;
  transition: transform 0.3s cubic-bezier(0.445, 0.05, 0.55, 0.95);
  z-index: 100;
}

.bi-arrow-left:hover {
  color: red;
  transform: scale(1.05);
}

.bi-pencil-square {
  font-size: 35px;
  position: fixed;
  right: 50px;
  top: 75px;
  cursor: pointer;
  transition: transform 0.3s cubic-bezier(0.445, 0.05, 0.55, 0.95);
}

.bi-pencil-square:hover {
  color: blue;
  transform: scale(1.2);
  z-index: 1;
}

.bi-plus-square {
  font-size: 34px;
  position: fixed;
  right: 50px;
  top: 120px;
  cursor: pointer;
  transition: transform 0.3s cubic-bezier(0.445, 0.05, 0.55, 0.95);
}

.bi-plus-square:hover {
  color: blue;
  transform: scale(1.2);
  z-index: 1;
}

.bi-info-square {
  font-size: 34px;
  position: fixed;
  right: 50px;
  top: 165px;
  cursor: pointer;
  transition: transform 0.3s cubic-bezier(0.445, 0.05, 0.55, 0.95);
}

.card-info-employee {
  background-color: rgb(234, 225, 225);
  font-size: 10px;
  position: fixed;
  top: 190px;
  right: 82px;
  width: 300px;
  z-index: 2;
  border-radius: 40px 10px 50px 20px;
  padding: 20px;
}

.search {
  height: 35px;
  width: 100px;
  margin-top: 5px;
  font-style: italic;
}

.bi-search {
  position: absolute;
  top: 7px;
  font-size: 22px;
  left: 180px;
  color: #777;
}

.title-h1 {
  font-family: "Poetsen One", sans-serif;
  font-weight: 200;
  font-style: normal;
}

.pkwt {
  margin: 10px;
  padding: 50px;
  border-radius: 10px;
  border: 1px solid grey;
  background-color: #f0f0f0;
  height: 70vh;
}

.no-pkwt {
  font-size: 30px;
  font-weight: bold;
  text-decoration: underline;
}

.date-pkwt {
  padding-left: 20px;
  font-size: 20px;
}

.duration-pkwt {
  padding-left: 20px;
  font-size: 20px;
}

.total-duration-pkwt {
  font-size: 25px;
  font-weight: bold;
  margin-top: 10px;
}

.position-pkwt {
  font-size: 25px;
  margin-top: 20px;
  font-weight: bold;
}

.notes-pkwt {
  font-size: 13px;
  color: grey;
}

.poetsen-one-regular {
  font-family: "Poetsen One", sans-serif;
  font-weight: 200;
  font-style: normal;
}

.menu-sidebar {
  font-size: 20px;
  width: 100%;
  font-family: "Poetsen One", sans-serif;
  padding: 10px;
  border-radius: 10px;
  transition: transform 0.3s cubic-bezier(0.445, 0.05, 0.55, 0.95);
}

.menu-sidebar.active-menu {
  cursor: pointer;
  background-color: aquamarine;
}

.menu-sidebar:hover {
  cursor: pointer;
  background-color: white;
  transform: scale(1.05);
}

.detail-employee {
  position: fixed;
  font-weight: bold;
  right: 50px;
  bottom: 50px;
  cursor: pointer;
  padding: 10px;
  border-radius: 5px;
  transition: transform 0.3s cubic-bezier(0.445, 0.05, 0.55, 0.95);
}

.detail-employee:hover {
  color: blue;
  transform: scale(1.2);
  z-index: 1;
}

.input-underlined {
  border: none;
  border-bottom: 1px solid #ccc;
  outline: none;
  background-color: transparent;
  padding: 5px 0;
  width: 100%;
  box-sizing: border-box;
}

.input-underlined:focus {
  border-bottom-color: #09d840;
}

.input-none {
  border: none;
  outline: none;
  background-color: transparent;
}

.input-vacation {
  transition: transform 0.3s cubic-bezier(0.445, 0.05, 0.55, 0.95);
}

.input-vacation:hover {
  color: blue;
  transform: scale(1.2);
}

.hidden {
  display: none;
}

.sticky-header {
  position: sticky;
  top: 63px;
}

.input-notice {
  position: 'absolute';
  right: '20px';
  top: '100px';
  z-index: 1000;
  width: '400px';
}

.tooltip-listKPI {
  margin-top: -410px;
  margin-left: -140px;
}

@media (max-width: 1024px) {
  .tooltip-listKPI {
    margin-top: -410px;
    margin-left: -60px;
  }
}

@media (max-width: 1800px) {
  .tooltip-listKPI {
    margin-top: -410px;
    margin-left: -90px;
  }
}

@media (max-width: 1700px) {
  .tooltip-listKPI {
    margin-top: -215px;
    margin-left: -50px;
  }
}

@media (max-width: 1500px) {
  .tooltip-listKPI {
    margin-top: -410px;
    margin-left: -40px;
  }
}

@media (max-width: 768px) {
  .tooltip-listKPI {
    margin-top: -410px;
    margin-left: -50px;
    font-size: 12px;
  }
}